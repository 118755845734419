import features from '../../../featureToggle.json';
import { ReactSettings } from '../Constants';


const setEnvVar = (env) => {
  if (env) {
    return env;
  }
  return 'prod';
};

const setReturnValue = (env, name) => {
  const returnVal = features[env] != null && features[env][name] === true;
  return returnVal;
};

const isFeatureEnabled = (name) => {
  // we need global to get out of webpack bundle on server side
  const windowObject = global.window || window;
  let env = windowObject ?
    (windowObject[ReactSettings.participantProfile]
      || windowObject[ReactSettings.participantDetails]
      || windowObject[ReactSettings.participantList]
      || windowObject[ReactSettings.participantDirectory]
      || windowObject[ReactSettings.featureFilters]
      || windowObject[ReactSettings.reactSettingsParticipantSettings]
      || windowObject[ReactSettings.default])?.environment :
    global.process.env.environment;
  env = setEnvVar(env);
  return setReturnValue(env, name);
};

const Keys = {
  OBJECTIVES: 'objectives',
  USE_NEW_SIGNEDASSETS_QUERY: 'use_new_signedassets_query',
  PARTICIPANT_PHONE_EDIT: 'participant_phone_edit',
  PARTICIPANT_COMPANY_EDIT: 'participant_company_edit',
  PARTICIPANT_NAME_AND_JOBTITLE_EDIT: 'participant_name_and_jobtitle_edit',
  CDN_URL_IN_REACTSETTINGS: 'cdn_url_in_reactsettings',
  SHOW_CREATED_DATE: 'show_created_date',
  PARTICIPANT_EMAIL_AND_PHONE_COPY_CLIPBOARD: 'participant_email_and_phone_copy_clipboard',
  DIRECTORY_FIRST_NAME_SORT: 'directory_first_name_sort',
  SORT_STICKY_URL: 'sort_sticky_url',
  MBO_NEEDS_COMPONENT: 'mbo_needs_component',
  ACTIVATION_MAIL_CONFIRMATION: 'activation_mail_confirmation',
  HIDE_CONTACT_DETAILS: 'hide_contact_details',
  SHOW_PARTICIPANT_FAVOURITE_BUTTON: 'show_participant_favourite_button',
  TWO_STAGE_EMAIL_ACTIVATION: 'two_stage_email_activation',
  PARTICIPANT_FEATURE_FILTERS: 'participant_feature_filters',
  PARTICIPANT_DIRECTORY_ALGOLIA_FEATURE_FILTERS: 'participant_directory_algolia_feature_filters',
  MANAGE_FEATURE_FILTERS: 'manage_feature_filters',
  SHOW_FEATURE_FILTERS: 'show_feature_filters',
  PARTICIPANT_ENGAGEMENT_REPORT: 'participant_engagement_report',
  SHOW_PARTICIPANT_NETWORKING_BUTTON: 'show_participant_networking_button',
  PARTICIPANTS_IN_MY_NETWORK_FILTER: 'participants_in_my_network_filter',
  NETWORKING_FOR_PRIMARY_EVENT_EDITION: 'networking_for_primary_event_edition',
  PARTICIPANTS_SETTINGS_DROPDOWN: 'participants_settings_dropdown',
  EDIT_FEATURE_FILTER: 'edit_feature_filter',
  TRACK_MIXPANEL_EVENTS: 'trackMixpanelEvents',
  PROFILE_IMAGE_ALERT: 'profile_image_alert',
  PARTICIPANT_ITEM_PPSLIST: 'participant_item_ppslist',
  PROFILE_IMAGE_ALERT_PD: 'profile_image_alert_pd',
  PARTICIPANT_ITEM_DESCRIPTION: 'participant_item_description',
  PD_SHOW_COUNTRY_FILTER: 'pd_show_country_filter',
  SHOW_SURVEY_POPUP: 'show_survey_popup',
  PAR_DIR_MIXPANEL_CLICKEVENTS: 'par_dir_mixpanel_clickevents',
  PARTICIPANT_PROFILE_HIGHLIGHT: 'participant_profile_highlight',
  LINKEDIN_BUTTON_ENABLED: 'linkedin_button_enabled'
};

export default { isFeatureEnabled, Keys };
